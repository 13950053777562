<template>
  <Layout ref="main">
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12 my-3"> 
        <ul class="list-group">
        <li class="list-group-item  mb-1 py-1 rounded" v-for="(value, index) in menu" :key="index">
       
          <router-link class="fw-medium text-body d-flex align-items-center custom-link" 
          :title="$t(value.title)" 
          :to="linkTo(value.routerName)">
          <span><i :class="'text-primary me-3 font-size-18 '+value.icon" ></i></span>  
          <span>{{$t(value.title)}}</span>  
          <span class="font-size-24 ms-auto"><i class="mdi mdi-chevron-right text-muted "></i></span>
         </router-link>    
        </li>
      </ul>
      </div>
    </div>
    <Lottie :path="'animate/profile.json'" :title="$t('siteLang.MyProfile')" :info="'Loading..Please Wait.'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    //PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.MyProfile'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  watch: {
    '$route.name': {
      immediate: true, // This will trigger the watcher immediately on component mount
      handler(newQuery, oldQuery) {
        // Handle the query change here
        console.log('New query:', newQuery);
        console.log('Old query:', oldQuery);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
   next(vm => {
     vm.prevRoute = from
   })

 },
  data() {
    return {
      prevRoute:{
        name:"",
        query:{
           status:""
        },
      },
      loading:false,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      menu:[
        {
          title:"siteLang.IdentityInformation",
          routerName:"identity information",
          icon:"mdi mdi-card-account-details-outline"
        },
        {
          title:"siteLang.DataInformation",
          routerName:"data information",
          icon:"mdi mdi-card-text-outline"
        },
        {
          title:"siteLang.BankCardInformation",
          routerName:"bank information",
          icon:"uil uil-credit-card font-size-19"
        },
        // {
        //   title:"siteLang.PhoneNumberAuthetication",
        //   routerName:"me",
        //   icon:"mdi mdi-cellphone"
          
        // },
        // {
        //   title:"siteLang.MySignature",
        //   routerName:"me",
        //   icon:"mdi mdi-draw"
        // },
      ],
    };
  },
  middleware: "authentication",
  mounted(){
    this.$refs.main.changeVerticalTopBar("me",true)
    this.$refs.main.setPageTitle(this.$t('siteLang.MyProfile'))
    this.title = this.$t('siteLang.MyProfile')
    this.items[1].text = this.$t('siteLang.MyProfile') 
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    
     if (this.prevRoute.name=="me"){
      this.$refs.lottieFunc.showHide = true
      this.getData()
    }

  },
  created(){
   
  },
  methods:{
    linkTo(value) {
      return {
          name: value
       };
    },
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        axios({
            method: "post",
            url: appConfig.APIHost + "controller/webapp/getMyProfile",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              console.log(resData.data.profile)
              const returnData = resData.data.profile
              const data = {
                  "name": returnData.name,
                  "phone": returnData.phone,
                  "address": returnData.address,
                  "educationLevel": returnData.educationLevel,
                  "occupation": returnData.name,
                  "income": returnData.income,
                  "house": returnData.house,
                  "car": returnData.car,
                  "bankName": returnData.bankName,
                  "accountNumber": returnData.accountNumber,
                  "acountName":returnData.acountName,
                  "icNumber": returnData.icNumber,
                  "icFrontImg": returnData.icFrontImg,
                  "icBackImg": returnData.icBackImg,
                  "signatureImg":returnData.signatureImg
                } 
              this.$refs.commonFunc.setProfileInfo(data)
              const updateData = {
                  "name": returnData.name,
              }
              this.$refs.commonFunc.updateData(JSON.stringify(updateData))
              this.$refs.lottieFunc.showHide = false
              this.loading = false;
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.loading = false;
            }
            this.loading = false;
            this.$Progress.finish();
            this.showLottie = false
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    }
  }
};
</script>